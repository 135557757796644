import * as React from "react";
import { Layout, LinkButton, SEO } from "../components";
import { QuoteForm } from "../components";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const CitibanamexPage = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        ...SiteInformationFragment
      }
    }
  `);

  const metadata = site.siteMetadata;
  const metaPage = "citibanamex";
  const metaTitle = "Citibanamex + Bright";
  const metaDescription =
    "¡Disfruta de los beneficios de tener paneles solares sin comprarlos!";

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": metadata.siteUrl,
        url: metadata.siteUrl,
        name: metadata.title,
        description: metaDescription,
        inLanguage: "es-MX",
      },
      {
        "@type": "ImageObject",
        "@id": `${metadata.siteUrl}${metadata.defaultOgImage}#primaryimage`,
        inLanguage: "es-MX",
        url: `${metadata.siteUrl}${metadata.defaultOgImage}`,
      },
      {
        "@type": "WebPage",
        "@id": `${metadata.siteUrl}/${metaPage}/#webpage`,
        url: `${metadata.siteUrl}/${metaPage}`,
        name: `${metaTitle} · ${metadata.title}`,
        isPartOf: { "@id": metadata.siteUrl },
        primaryImageOfPage: {
          "@id": `${metadata.siteUrl}${metadata.defaultOgImage}`,
        },
        breadcrumb: {
          "@id": `${metadata.siteUrl}/${metaPage}/#breadcrumb`,
        },
        inLanguage: "es-MX",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: [`${metadata.siteUrl}/${metaPage}`],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": `${metadata.siteUrl}/${metaPage}#breadcrumb`,
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": metadata.siteUrl,
              url: metadata.siteUrl,
              name: "Inicio",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": `${metadata.siteUrl}/${metaPage}`,
              url: `${metadata.siteUrl}/${metaPage}`,
              name: `${metaTitle} · ${metadata.title}`,
            },
          },
        ],
      },
    ],
  };

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        schemaMarkup={schemaMarkup}
      />
      <div className="bg-white py-24">
        <div className="mx-auto px-4 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl text-center">
          <h1 className="font-bold text-gray-900 tracking-tight text-4xl md:text-5xl text-center">
            Citibanamex + Bright
          </h1>
          <div className="italic text-xl mt-4">
            ¡Disfruta de los beneficios de tener paneles solares sin comprarlos!
          </div>
          <div className="mt-16">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-16">
              <div>
                <StaticImage
                  src="../images/citibanamex_promo.png"
                  alt="Bright + Citibanamex"
                />
                <div className="text-left space-y-2">
                  <ul className="space-y-4">
                    <li>
                      ¿Te gustaría probar paneles solares sin tener que invertir
                      en el sistema y además tener 2 meses gratis?
                    </li>
                    <li>
                      Si eres cliente Citibanamex ¡Ahora puedes hacerlo con
                      Bright!
                    </li>
                    <li>Promoción válida al 30/09/2022</li>
                  </ul>

                  <div className="text-sm text-gray-500 pt-8">
                    <b>Términos y condiciones:</b>
                    <br />
                    <ol className="list-decimal list-inside">
                      <li>Productos suscripción, no aplica T01.</li>
                      <li>
                        Para calificar, tienes que estar pagando la tarifa DAC
                        (alto consumo) de la CFE.
                      </li>
                      <li>
                        Aplican departamentos de máximo 4 pisos con derechos al
                        techo.
                      </li>
                    </ol>
                    Aplican términos y condiciones
                  </div>
                </div>
              </div>
              <div className="w-full md:px-0 text-left">
                <QuoteForm
                  form="citibanamex"
                  howDidYouHearAboutUs="citibanamex"
                  commercialWarning="Esta promoción no es válida para negocios"
                  interactiveProposalVariant={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Us Start */}
      <div className="bg-gray-50">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-blue-500 sm:text-4xl">
            ¿Tienes preguntas?
          </h2>

          <div className="mx-auto mt-8 max-w-xs w-56">
            <LinkButton shadow arrow size="large" to="/contacto">
              Contáctanos
            </LinkButton>
          </div>
        </div>
      </div>
      {/* Contact Us End */}
    </Layout>
  );
};

export default CitibanamexPage;
